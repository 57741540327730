/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
@import '~ngx-toastr/toastr.css'; 
/* a {
  color: #0366d6;
} */

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}



.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: rgb(23, 22, 22);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: opacity 0.3s linear;
}

.showHand{
  cursor: pointer;
}

.hideHand{
  cursor: default !important;
}

html, body { height: 100%; }
body { margin: 0; }

.btnMapClose{
  margin-top: 5px;
  margin-right: 5px;
}

.divloader{
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: center;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #209cd8;
}

.custom-button-spinner circle {
  stroke: #ffffff;
}

.custom-dialog-container .mat-dialog-container {
  padding: unset !important;
}